/* style.css */
.user_btn,
.close_btn {
  /* background-color: white; */
    color: white;
    cursor: pointer;
    width: 7vw;
    height: 5vh;
    border-radius: 6px;
    border: 1px solid;
    margin-left: 10px;
}

.user_btn.activ,
.close_btn.activ {
  background-color: #2657cf;
  color: white;
}

.raiseticket_btn{
  background-color: #2657cf;
  color: white;
  border: none;
  border-radius: 5px;
  width: 10vw;
  height: 6vh;
  cursor: pointer;
  margin-right: 5.5rem;
}

.Reply_btn{
  background-color: #4fd1c5;
  color: white;
  border-radius: 5px;
  width: 6vw;
  height: 5vh;
  cursor: pointer;
  border: none;
}
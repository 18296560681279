html,
body {
  overflow-x: hidden;
}

body {
  margin: 0;
  padding: 0;
  width: 100%;
}

.error {
  /* color:rgba(255,255,255,0.6); */
  color: red;
  font-size: 0.6rem;
  margin-top: 2px;
}

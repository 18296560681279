.apk_container {
  padding: 0 0 1rem 0;
}

.apk_table {
  border-collapse: collapse;
  max-width: max-content;
  min-width: 100%;
  background-color: #2657cf;
}

.apk_header {
  background-color: var(--color-pink);
  color: var(--color-white);
  font-family: var(--fontfamily-sansserif);
  padding: 0.5rem;
}

.apk_data {
  text-align: center;
  font-family: var(--fontfamily-sansserif);
  padding: 0.5rem;
  border-bottom: 1px solid grey;
}

.apk_file_btnnnn {
  background-color: #2657cf;
  color: white;
  width: 10vw;
  height: 5vh;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  margin-top: 1rem;
}
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 500px;
  border-radius: 10px;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  margin-bottom: 5px;
}




.submt_btn {
  width: 10vw;
  height: 6vh;
  background-color: #2657cf;
  color: white;
  border-radius: 5px;

}
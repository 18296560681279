.sbmt_btnn{
    background-color: #2657cf;
    color: white;
    border-radius: 5px;
    border: none;
    width: 10vw;
    height: 5vh;
    cursor: pointer;
    margin-top: 1rem;
    margin-left: 1rem;
}

.input_1{
    display: flex;
    flex-direction: column;
  
    border-radius: 5px;
    margin-left: 1rem;
}

.input_1 input{
    width: 30vw;
    height: 6vh;
    border-radius: 5px;
    border: none;
    margin-top: 10px;
}

.error {
  color: red;
  font-size: 15px;
}
.add_ticket_issue_btn{
    background-color: #2657cf;
    color: white;
    cursor: pointer;
    width: 10vw;
    height: 5vh;
    border: none;
    border-radius: 5px;
    margin-top: 1rem;
    margin-left: 1rem;
}
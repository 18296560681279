.loader_bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 111111;
}

.loader_icon_bg {
  /* background-color: #fff; */
  width: 300px;
  height: 220px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.loader_icon {
  width: 220px;
  height: 120px;
}

.loader_msgs {
  text-align: center;
  margin: 10px 0 20px 0;
  font-size: var(--font-small);
}

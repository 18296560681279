/* Base button styles for withdrawal list */
.withdrawal-list-btn {

  height: 5vh;
  margin: 5px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: all 0.3s ease;
  margin-top: 1rem;
}

/* Normal button style */
.withdrawal-list-btn {
  background-color: #f0f0f0;
  color: #333;

}

.withdrawal-list-btn:hover {
  background-color: #e0e0e0;
}

/* Active button style */
.withdrawal-list-btn.active {
  background-color: #007bff;
  color: white;
}

.withdrawal-list-btn.active:hover {
  background-color: #0056b3;
}

/* Container for the buttons */
.withdrawal-list-btn-container {
  display: flex;
  margin-bottom: 20px;
}

/* Styles for the table container */
.commission-slab-container {
  margin-top: 20px;
}
